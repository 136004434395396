import { KeyValue } from '@angular/common';

export declare interface IdName<T, S = number> {
  id: S;
  name: T;
  selected?: boolean;
}

export function idNames(T): Array<IdName<any>> {
  return Object.keys(T).filter(type => typeof T[type] === 'string').map(key => ({name: T[key], id: +key}));
}

export function idNameString(T): Array<IdName<string, string>> {
  return Object.keys(T).filter(type => typeof T[type] === 'string').map(key => ({ name: T[key], id: key }));
}

export function keyValues(T): Array<KeyValue<number, string>> {
  return Object.keys(T).filter(type => !isNaN(type as any) && type !== 'values').map(key => ({key: +key, value: T[key]}));
}

export function asStrings(T): Array<string> {
  return Object.values<string>(T).filter((x) => typeof x === 'string');
}
